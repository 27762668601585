.navbar {
    z-index: 10001;
    position: fixed;
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    padding: 0 1rem 0 1rem;
    background-size: 100% 100% !important;
    overflow: hidden;
}

.navbarCollapse {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.navbarItem {
    width: 11%;
    background-size: 100% 100%;
    text-align: center;
    background-repeat: no-repeat;
    margin: 10px 2px 50px 2px;;
}

.navbarLink, .navbarLink:hover {
    text-decoration: none;
    background-color: transparent;
    padding: 1rem 0.5rem;
    font-size: 1.2vw;
    line-height: 10pt;
    color: #c49559;
    font-weight: 600;
    float: left;
    width: 100%;
    font-style: italic;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.navbarIcon {
    display: none;
}

.navbarImage {
    max-height: 1.5rem;
    max-width: 1.5rem;
}

.navbarLogo {
    padding-top: 0.5rem;
    padding-bottom: 2rem;
    background-size: 100% 100%;
    height: 110px;
    background-repeat: no-repeat;
    text-decoration: none;
    background-color: transparent;
    float: left;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 150px;
}

.srOnlyTop {
    float: right;
    width: calc(100% - 54px);
    text-align: left;
    font-family: poppins-regular;
    font-size: 1vw;
}

.srOnlyBtm {
    float: left;
    width: 100%;
    word-spacing: 0;
    font-family: poppins-regular;
    font-size: 0.8vw;
    margin: 10px 0 0;
}

.timeIcon {
    max-width: 100%;
    min-width: 30px;
    cursor: pointer;
}

.navbarCollapseMobile {
    float: right;
    display: block;
    text-align: left;
    background-color: #2f2821;
    width: 70%;
    z-index: 10001;
    position:fixed;
    top: 70px;
    right: 0;
    height: 100%;
    overflow: auto;
    padding-bottom: 10rem;
}

.navbarItemMobile {
    background-size: 100% 100%;
    text-align: center;
    background-repeat: no-repeat;
    margin: 1rem;
    padding: 1rem;
}

.navbarLinkMobile, .navbarLinkMobile:hover {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #c49559;
    font-weight: 600;   
    font-style: italic;
    font-size: 25px;
}

.navbarMobile {
    display: none;
}

.backdrop {
    display: block;
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: black;
    opacity: 0.5;
    z-index: 10001;
  }

@media screen and (max-width: 991px) {
    .navbar {
        display: none;
    }

    .navbarIcon {
        display: block;
    }

    .navbarMobile {
        display: block;
        z-index: 10001;
        position: fixed;
        width: 100%;
        padding: 0 0.5rem 15px 0.5rem;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .navbarHeaderMobile {
        display: flex;
        flex-direction: row;
        color: #fff;
        margin: auto;
        width: 100%;
        float: left;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }
}

@media screen and (min-width: 540px) {
    .navbarLinkMobile {
        justify-content: start;
    }  
    .navbarLinkMobile img {
        margin-right: 1rem;
    }
}